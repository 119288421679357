.fade-entered {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}
.fade-exiting,
.fade-exited {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.slide-right-entered {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}
.slide-right-exiting,
.slide-right-exited {
  transform: translateX(-100%);
  transition: transform 300ms ease-in-out;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: #fafafa !important;
  -webkit-box-shadow: 0 0 0px 1000px #fafafa inset !important;
}

input:-moz-autofill,
input:-moz-autofill:hover,
input:-moz-autofill:focus,
input:-moz-autofill:active {
  background-color: #fafafa !important;
}
